<template>
  <nav class="sidebar-edges">
    <template v-for="(navigationItem, index) in navigationItems">
      <feature-toggle
        v-if="navigationItem.featureFlag"
        :id="navigationItem.featureFlag"
        :key="index"
      >
        <router-link
          :key="navigationItem.name"
          :to="{ name: navigationItem.name }"
          class="button item"
          :class="{ active: appContextActive(navigationItem.name) }"
          v-test:menuOption="navigationItem.name"
        >
          {{ navigationItem.text }}
        </router-link>
      </feature-toggle>
      <router-link
        v-else
        :key="navigationItem.name"
        :to="{ name: navigationItem.name }"
        class="button item"
        :class="{ active: appContextActive(navigationItem.name) }"
        v-test:menuOption="navigationItem.name"
      >
        {{ navigationItem.text }}
      </router-link>
    </template>
    <SupportCenterLink class="button item" v-test:menuOption="'Support'">
      <translate>Support</translate>
    </SupportCenterLink>
    <button
      class="button logout"
      @click="onClickLogout"
      type="button"
      v-test:logout
    >
      <Icon symbol="logout" class="icon" />
      <span v-text="logoutText" />
    </button>
  </nav>
</template>

<script>
  import { mapActions } from 'vuex';
  import Icon from '@/components/Icon/Icon.vue';
  import { MAIL, CONTACTS, SETTINGS, ALIASES } from '@/router/named-routes';
  import SupportCenterLink from './SupportCenterLink.vue';

  export default {
    components: {
      Icon,
      SupportCenterLink,
    },
    props: {
      showLabels: { type: Boolean, default: false },
    },
    computed: {
      navigationItems() {
        return [
          {
            name: MAIL,
            text: this.showLabels ? this.$gettext('Mail') : '',
          },
          {
            name: CONTACTS,
            text: this.showLabels ? this.$gettext('Contacts') : '',
          },
          {
            name: ALIASES,
            text: this.showLabels ? this.$gettext('Aliases') : '',
          },
          {
            name: SETTINGS,
            text: this.showLabels ? this.$gettext('Settings') : '',
          },
        ];
      },
      logoutText() {
        return this.showLabels ? this.$gettext('Log out') : '';
      },
    },
    methods: {
      ...mapActions('authentication', ['logout']),
      async onClickLogout() {
        await this.logout();
      },
      appContextActive(name) {
        if (this.$route.matched[0]) {
          return this.$route.matched[0].name === name;
        } else {
          return false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  nav {
    display: flex;
  }

  .item {
    align-items: center;
    border-radius: $border-radius-default;
    color: $gray;
    display: flex;
    padding: $spacing-xs;

    &:focus,
    &:hover {
      background-color: $gray-500;
    }

    &:last-of-type {
      padding-inline-end: $spacing-default;
    }

    @media screen and (min-width: $messages-single-line-breakpoint) {
      padding: 0 $spacing-default;
    }

    @media screen and (min-width: $sidebar-collapsing-breakpoint) {
      border-radius: 0;
      color: $gray;
      display: flex;
      font-weight: $font-weight-normal;
      height: 100%;

      &:focus,
      &:hover {
        background-color: $gray-500;
      }
    }

    &.active {
      background-color: $brand-blue;
      color: $white;
      font-weight: $font-weight-bold;

      &:focus,
      &:hover {
        background-color: $brand-blue-alt;
        color: $white;
      }

      @media all and (min-width: $sidebar-collapsing-breakpoint) {
        background-color: $gray-500;
        border-block-start: 3px solid $brand-blue;
        color: $gray;
        font-weight: $font-weight-bold;

        &:focus,
        &:hover {
          background-color: $gray-500;
          color: $gray;
        }
      }
    }
  }

  button.logout {
    border-radius: 0;
    position: relative;
    stroke: $brand-blue;

    &:hover,
    &:focus {
      background-color: transparent;
      stroke: $brand-blue-alt;
    }

    &::before {
      border-inline-start: 1px solid $gray-400;
      content: '';
      height: 16px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    // only visible on wider screens; show either icon or text
    // depending on available width. (on smaller screens the sidebar
    // shows a logout button.)
    @media all and (width < $sidebar-collapsing-breakpoint) {
      display: none;
    }

    @media all and (width < $messages-single-line-breakpoint) {
      span {
        @include visually-hidden;
      }
    }

    @media all and (width >= $messages-single-line-breakpoint) {
      span {
        margin: 0;
      }

      .icon {
        display: none;
      }
    }
  }
</style>
